/** @format */

import FranceFlag from '@/assets/icons/france_flag.svg';
import ItalyFlag from '@/assets/icons/italy_flag.svg';
import SpainFlag from '@/assets/icons/spain_flag.svg';
import { Image } from '@/components/Image';
import { StyledButton } from '@/components/ui/Button/StyledButton';
import { Icon } from '@/components/ui/Icon';
import { RichText } from '@/components/ui/RichText';
import { H1, H2, H3, H4, P, Subtitle } from '@/components/ui/Typography';
import { useModal } from '@/context/modalContext';
import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { GTMCTAClick } from '@/lib/google-tag-manager';
import { ExternalLink, InternalLink } from '@/types/page';
import { LandingCard } from '@/types/shared';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Entry } from 'contentful';
import React, { ReactNode, useMemo } from 'react';
import { breakpointUpTo } from 'src/theme/mixins';
import { MagicLink } from 'src/utils/magicLink';
import styled, { DefaultTheme } from 'styled-components';

type CardProps = {
  card: LandingCard;
};

const FlagSelector = (country: string) => {
  let flag = '';
  switch (country) {
    case 'Itália':
    case 'Italia':
    case 'Italy':
    case 'Italie':
      flag = ItalyFlag;
      break;
    case 'Spagna':
    case 'Espagne':
    case 'España':
    case 'Espanha':
    case 'Spain':
      flag = SpainFlag;
      break;
    case 'Francia':
    case 'France':
    case 'França':
      flag = FranceFlag;
      break;
    default:
      return '';
  }

  return <Icon icon={flag} width={28} height={20} />;
};

const Card: React.FC<CardProps> = ({ card }) => {
  const mediaQueryIndex = useMediaQueryIndex();
  const mobile = useMemo(() => !!(mediaQueryIndex !== null && mediaQueryIndex < 3), [mediaQueryIndex]);
  const { toggleModal, setModalContent } = useModal();

  const openModalWithContent = () => {
    GTMCTAClick('coliving', 'explore_coliving_space');
    const modalDynamicContent = (
      <>
        <Badge className="modal-badge">{card.badge}</Badge>
        <RichText text={card.linksModal.fields.body} customOptions={DEFAULT_OPTIONS} />
      </>
    );
    setModalContent(modalDynamicContent);
    toggleModal();
  };

  return (
    <Container className="card">
      <TextContainer>
        <Badge>{card.badge}</Badge>
        <TextWrapper>
          <RichText text={mobile ? card.mobileBody : card.body} />
        </TextWrapper>
        {card.linksModalButtonText && card.linksModal && (
          <LinkWrapper onClick={openModalWithContent}>{card.linksModalButtonText}</LinkWrapper>
        )}
      </TextContainer>
      {card.images && card.images.length > 0 && (
        <ImageWrapper>
          <Image image={card.images[0]} />
          <Image image={card.images[1]} />
        </ImageWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 90%;
  background: ${({ theme }) => theme.color.landing.white};
  background: rgb(250, 250, 250);
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.color.landing.white} 0%,
    ${({ theme }) => theme.color.landing.white} 84%,
    rgba(255, 0, 0, 0) 84%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 12%;
    left: calc(5px + 16px);
    width: 82%;
    height: 85%;
    box-shadow: 0 8px 20px #0000001a;
    z-index: -1;
  }

  ${breakpointUpTo.sm`
    display: block;
    position: relative;
    background: unset;
  `}
`;

const TextContainer = styled.div`
  padding-left: clamp(2rem, -1.7941rem + 5.8134vw, 3.438rem);
  max-width: calc(348px + clamp(1rem, -1.7941rem + 5.8134vw, 3.438rem));
  padding-block: clamp(1.5rem, -1.2219rem + 5.6632vw, 3.875rem);

  ${breakpointUpTo.md`
    min-width: calc(304px + clamp(1rem, -1.7941rem + 5.8134vw, 3.438rem));
    padding-inline: clamp(2rem, -1.7941rem + 5.8134vw, 3.438rem);
  `}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-inline: clamp(2rem, -1.7941rem + 5.8134vw, 3.438rem);
  }

  ${breakpointUpTo.sm`
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    min-width: calc(100% - 1.5*2rem);
    width: calc(100% - 1.5*2rem);
    padding: 1.5rem 0.625rem;
    background-color: ${({ theme }: { theme: DefaultTheme }) => theme.color.landing.white};
    z-index: 2;
    border-radius: 16px;
    box-shadow: 0 8px 20px #0000001a;
  `};
`;

const Badge = styled.div`
  display: block;

  color: ${({ theme }) => theme.color.teal.light};
  border: 1px solid ${({ theme }) => theme.color.gray.light};
  font-size: 1.125rem;
  font-weight: 500;
  max-width: max-content;
  padding-inline: 1rem;
  padding-block: 0.25rem;
  border-radius: 1.25rem;
  text-transform: capitalize;
  margin-bottom: clamp(0.5rem, -0.6461rem + 2.3845vw, 1.5rem);

  ${breakpointUpTo.sm`
    font-size: 0.75rem;
    color: ${({ theme }: { theme: DefaultTheme }) => theme.color.white};
    border: 1px solid ${({ theme }: { theme: DefaultTheme }) => theme.color.white};
    position: absolute;
    top: -64px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #01343999;
  `}
`;

const TextWrapper = styled.div`
  h2 {
    font-size: clamp(1rem, 0.8333rem + 0.7407vw, 1.5rem);
    letter-spacing: normal;
    line-height: 1.24;
    margin-bottom: 8px;
    ${breakpointUpTo.sm`
        text-align: center;
    `}
  }
  p {
    font-size: 1rem;
    letter-spacing: normal;
    line-height: 1.24;
    ${breakpointUpTo.sm`
        text-align: center;
    `}
  }
  & > p:last-of-type {
    padding-top: 8px;
    ${breakpointUpTo.sm`
        padding-top: 0;
        text-align: center;
    `}
  }
  a {
    margin-top: clamp(1.5rem, 1.0833rem + 1.8519vw, 2.75rem);
    text-align: center;
    ${breakpointUpTo.sm`
      width:100%;
      height: 100%;
      padding-inline: 1rem;
      span {
        font-size: 0.875rem;
        font-family: ${({ theme }: { theme: DefaultTheme }) => theme.font.body};
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        letter-spacing: 0em;

      }
    `}
  }
`;

const LinkWrapper = styled.div`
  &:hover {
    color: #ffffff;
    background-color: #3aadb4;
  }
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  width: auto;
  height: auto;
  border: none;
  border-radius: 2.25rem;
  -webkit-transition: background 0.5s cubic-bezier(0.36, 0.33, 0, 1);
  transition: background 0.5s cubic-bezier(0.36, 0.33, 0, 1);
  cursor: pointer;
  padding: 0.75rem 1.25rem;
  background: ${({ theme }) => theme.color.yellow};
  color: ${({ theme }) => theme.color.teal.dark};
  font-weight: 600;
  text-align: center;
  margin-top: clamp(1.5rem, 1.0833rem + 1.8519vw, 2.75rem);
  ${breakpointUpTo.sm`
        width:100%;
        height: 100%;
        padding-inline: 1rem;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
    `}
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  ${breakpointUpTo.sm`
    height: 100%;
    border-radius: 4px;
  `}
  overflow: hidden;
  position: relative;
  .image-loader {
    animation-name: placeholder !important;

    @keyframes placeholder {
      0% {
        background-color: ${({ theme }) => theme.color.landing.white};
      }
      50% {
        background-color: ${({ theme }) => theme.color.landing.white};
      }
      100% {
        background-color: ${({ theme }) => theme.color.landing.white};
      }
    }
  }

  & > div:first-child {
    display: block;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }

  & > div:last-child {
    display: none;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }

  img {
    width: auto;
    height: 100%;
    right: -2px;
    top: 0px;
    position: absolute;
    object-fit: contain;
    object-position: bottom right;

    ${breakpointUpTo.md`
    object-position: center center;
    `}

    ${breakpointUpTo.sm`
        width: 100%;
        position: relative;
        object-fit: cover;
        border-radius: 4px;
    `}
  }
`;

const DEFAULT_OPTIONS: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <Subtitle>{children}</Subtitle>,
    [BLOCKS.HEADING_6]: (node, children) => <Subtitle>{children}</Subtitle>,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <P>{children}</P>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      if (
        node.data.target.sys.contentType.sys.id === 'internalLink' ||
        node.data.target.sys.contentType.sys.id === 'externalLink'
      ) {
        const item: Entry<InternalLink['fields'] | ExternalLink['fields']> = node.data.target;
        return (
          <StyledButton
            item={item}
            position={item.fields.position ?? 'right'}
            variant={item.fields.variant ?? 'bgTeal'}
            elementType="magic"
          >
            {FlagSelector(item.fields.label.substring(item.fields.label.lastIndexOf(' ') + 1))}
            {item.fields.label.substring(item.fields.label.lastIndexOf(' ') + 1)}
          </StyledButton>
        );
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      if (
        node.data.target.sys.contentType.sys.id === 'internalLink' ||
        node.data.target.sys.contentType.sys.id === 'externalLink'
      ) {
        const item: Entry<InternalLink['fields'] | ExternalLink['fields']> = node.data.target;

        return <MagicLink item={item} />;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (node.data.target.fields.file.contentType.includes('image')) {
        return <Image image={node.data.target} />;
      }
    },
  },
};

export default Card;
