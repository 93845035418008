/** @format */

import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { LandingCard } from '@/types/shared';
import { Entry } from 'contentful';
import React, { useEffect, useMemo, useState } from 'react';
import { breakpointFrom, breakpointUpTo } from 'src/theme/mixins';
import ShapeLandingIcon from '../../../assets/shape-landing.svg';
import { Icon } from '@/components/ui/Icon';
import Card from './Card';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import SwiperCore from 'swiper';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const LandingCardsSection: React.FC<LandingCardsSectionProps> = ({ cards }) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const mediaQueryIndex = useMediaQueryIndex();
  const mobile = useMemo(() => !!(mediaQueryIndex !== null && mediaQueryIndex < 2), [mediaQueryIndex]);
  const [load, setLoad] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  const filteredCards = useMemo(
    () =>
      cards.map(card => {
        return {
          ...card.fields,
          id: card.sys.id,
        };
      }),
    [cards],
  );

  const goToSlide = (index: number) => {
    if (swiper) {
      swiper.slideTo(index);
    }
  };

  const onSlideChangeStart: SwiperProps['onSlideChangeTransitionStart'] = swiper => {
    if (!swiper || !swiper.slides) return;

    swiper.slides.forEach(slide => {
      slide.classList.remove('active');
    });

    const activeSlide = swiper.slides[swiper.activeIndex];
    setActiveIndex(swiper.activeIndex);
    if (activeSlide) {
      activeSlide.classList.add('active');
    }
  };

  useEffect(() => {
    if (mediaQueryIndex !== null) {
      const timeoutId = setTimeout(() => {
        setShowSkeleton(false);
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [mediaQueryIndex]);

  return (
    <Container>
      <ShapeLandingIconWrapper icon={ShapeLandingIcon} height={396} width={346} />
      <TabHeader $showSkeleton={showSkeleton}>
        {filteredCards.map((tab, index) => (
          <TabButton key={tab.id} onClick={() => goToSlide(index)} className={index === activeIndex ? 'active' : ''}>
            <span
              dangerouslySetInnerHTML={{
                __html: tab.icon,
              }}
            />
            <div className="text-container">
              <span>{!mobile ? tab.title : tab.title.split(' ')[0]}</span>
              <span className="subtitle">{tab.subtitle}</span>
            </div>
          </TabButton>
        ))}
      </TabHeader>
      {showSkeleton && (
        <TabSkeletonWrapper>
          <TabStyledSkeleton />
        </TabSkeletonWrapper>
      )}

      <CardsContent>
        {showSkeleton && !load && <CardStyledSkeleton />}
        <StyledSwiper
          $load={load}
          onInit={() => setTimeout(() => setLoad(true), 300)}
          onSwiper={swiperInstance => setSwiper(swiperInstance)}
          onSlideChangeTransitionStart={onSlideChangeStart}
          slidesPerView="auto"
          centeredSlides={true}
          spaceBetween={16}
          keyboard={{
            enabled: true,
          }}
          breakpoints={{
            576: {
              spaceBetween: 16,
            },
            768: {
              spaceBetween: 24,
            },
            992: {
              spaceBetween: 24,
            },
            1200: {
              spaceBetween: 24,
            },
            1440: {
              spaceBetween: 32,
            },
          }}
        >
          {filteredCards.map((card, index) => (
            <SwiperSlide key={card.id}>
              <Card card={card} />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </CardsContent>
    </Container>
  );
};

interface LandingCardsSectionProps {
  cards: Entry<LandingCard>[];
}

const TabSkeletonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabStyledSkeleton = styled(Skeleton)`
  width: clamp(19.375rem, 8.75rem + 47.2222vw, 51.25rem);
  height: clamp(2.625rem, 1.8333rem + 3.5185vw, 5rem);
  border-radius: 1rem;
`;

const CardStyledSkeleton = styled(Skeleton)`
  width: calc(57.3125rem + 2rem);
  height: clamp(17.938rem, 15.2507rem + 11.9437vw, 26rem);
  ${breakpointUpTo.md`
      width: 80%;
    `}
  ${breakpointFrom.md`
      width: clamp(38.813rem, 17.6116rem + 44.1121vw, 57.3125rem);
    `}
    ${breakpointUpTo.sm`
      width: 16rem;
      padding-inline: 0;
    `}

border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`;

const Container = styled.div`
  z-index: 1;
  position: relative;
  margin-top: -1px;
  padding-bottom: 4rem;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.color.teal.dark} 0%,
    ${({ theme }) => theme.color.teal.dark} 50%,
    rgba(255, 0, 0, 0) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  ${breakpointUpTo.sm`
    padding-bottom: 0;
  `}
`;

const ShapeLandingIconWrapper = styled(Icon)`
  position: absolute;
  right: 0;
  transform: translate(0%, 0%);
  -webkit-background-size: contain !important;
  background-size: contain !important;
  background-repeat: no-repeat;
  z-index: -1;

  ${breakpointUpTo.md`
    display: none;
    `}
`;

const TabHeader = styled.div<{ $showSkeleton: boolean }>`
  display: ${({ $showSkeleton }) => ($showSkeleton ? 'none' : 'flex')};
  justify-content: center;
  gap: 1px;
`;

const TabButton = styled.button`
  display: flex;

  gap: clamp(0.25rem, 0rem + 1.1111vw, 1rem);
  align-items: center;
  background-color: ${({ theme }) => theme.color.landing.dark};
  padding: clamp(0.75rem, 0.6667rem + 0.3704vw, 1rem) clamp(1rem, 0.8333rem + 0.7407vw, 1.5rem);
  cursor: pointer;
  color: ${({ theme }) => theme.color.landing.gray.light};
  font-family: ${({ theme }) => theme.font.body};
  font-size: clamp(0.875rem, 0.75rem + 0.5556vw, 1.25rem);
  line-height: clamp(1.05rem, 0.9rem + 0.6667vw, 1.5rem);
  font-weight: 700;
  ${breakpointUpTo.sm`
    font-weight: 500;
  `}
  border: 0;
  transition: all 0.3s ease-in-out;

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  span.subtitle {
    color: ${({ theme }) => theme.color.landing.gray.dark};
    padding-top: 4px;
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;
    text-align: left;
    ${breakpointUpTo.sm`
    display: none;
    `}
  }

  &:hover:not(.active) {
    background-color: ${({ theme }) => theme.color.landing.darker};
  }

  &:focus {
    outline: none;
  }

  svg {
    transition: all 0.3s ease-in-out;
    & * {
      fill: ${({ theme }) => theme.color.landing.gray.light};
    }

    ${breakpointUpTo.sm`
      width:12px;
      height:auto;
    `}
  }

  &.active {
    background-color: ${({ theme }) => theme.color.yellow};
    color: ${({ theme }) => theme.color.teal.dark};
    svg * {
      fill: ${({ theme }) => theme.color.teal.dark};
    }
    span.subtitle {
      color: ${({ theme }) => theme.color.teal.dark};
    }
  }

  &:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  &:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;

const CardsContent = styled.div`
  padding-block: clamp(1.5rem, 0.9167rem + 2.5926vw, 3.25rem) 0; //clamp(68.125rem, 87.5623rem + -86.3881vw, 9.813rem)
  min-height: clamp(17.938rem, 15.5007rem + 10.8326vw, 26rem);
  display: flex;
  justify-content: center;
`;

const transformScaleTranslate = (margin: number, scaledMargin: number) => `
  transform-origin: center; 
  transform: scale(0.85) translateX(calc(-7.5% + (${margin}px - ${scaledMargin}px * 0.85)));
`;

// Swiper Styles
const StyledSwiper = styled(Swiper)<{ $load: boolean }>`
  opacity: ${({ $load }) => ($load ? '1' : '0')};
  width: 100%;
  padding-bottom: 2rem;
  ${breakpointUpTo.sm`
      padding-bottom: 7rem;
    `}
  .swiper-slide {
    scale: 1;
    transform-origin: center;
    max-width: calc(57.3125rem + 2rem);
    ${breakpointUpTo.md`
      max-width: 80%;
    `}
    ${breakpointFrom.md`
      max-width: clamp(38.813rem, 17.6116rem + 44.1121vw, 57.3125rem);
    `}
    ${breakpointUpTo.sm`
      max-width: 16rem;
      padding-inline: 0;
    `}
    width:100%;
    height: 110%;

    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    ${transformScaleTranslate(8, 16)}

    ${breakpointFrom.sm`
    ${transformScaleTranslate(8, 16)}
  `}
    ${breakpointFrom.md`
    ${transformScaleTranslate(12, 24)}
  `}
  ${breakpointFrom.lg`
    ${transformScaleTranslate(12, 24)}
  `}
  ${breakpointFrom.xl`
    ${transformScaleTranslate(12, 24)}
  `}
  ${breakpointFrom.xxl`
    ${transformScaleTranslate(16, 32)}
  `}

    &.swiper-slide-prev {
      transform: scale(0.85) translateX(calc(7.5% + 8px + (16px - 16px * 0.85)));
      ${breakpointFrom.sm`
    transform: scale(0.85) translateX(calc(7.5% + 8px + (16px - 16px * 0.85)));
  `}
      ${breakpointFrom.md`
    transform: scale(0.85) translateX(calc(7.5% + 12px + (24px - 24px * 0.85)));
  `}
  ${breakpointFrom.lg`
    transform: scale(0.85) translateX(calc(7.5% + 12px + (24px - 24px * 0.85)));
  `}
  ${breakpointFrom.xl`
    transform: scale(0.85) translateX(calc(7.5% + 12px + (24px - 24px * 0.85)));
  `}
  ${breakpointFrom.xxl`
    transform: scale(0.85) translateX(calc(7.5% + 16px + (32px - 32px * 0.85)));
  `}
    }

    &.swiper-slide-active {
      transform: scale(1);
    }
  }
`;
