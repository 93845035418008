/** @format */

// ModalContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

type ModalContextType = {
  isModalOpen: boolean;
  toggleModal: () => void;
  modalContent: ReactNode;
  setModalContent: (content: ReactNode) => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

type ModalProviderProps = {
  children: ReactNode;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
  }, [isModalOpen]);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <ModalContext.Provider value={{ isModalOpen, toggleModal, modalContent, setModalContent }}>
      {children}
    </ModalContext.Provider>
  );
};
